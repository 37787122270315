import { R4 } from '@ahryman40k/ts-fhir-types'
import { faCross, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  makeStyles,
  Theme,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from '@material-ui/core'
import { Autocomplete, Alert } from '@material-ui/lab'
import { CloseOutlined, DeleteOutlineOutlined } from '@material-ui/icons'
import InfoIcon from '@material-ui/icons/Info'

import { UnitServices } from 'models/units/unitServices'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  sendUnitInvitations,
  resetUnitAddReducerState,
} from 'redux/administration/unitSetup/unitInvitationSlice/unitInvitationSetupSlice'
import { RootState } from 'redux/rootReducer'
import { getUserDetails } from 'services/userDetailsService'
import { UnitTypes } from 'utils/constants/unit_types'
import { getUniqueTempId } from 'utils/fhirResoureHelpers/idHelpers'
import { logger } from 'utils/logger'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloLoadingIndicator } from 'wello-web-components'
import { kPrimaryLight } from 'configs/styles/muiThemes'

import { titleCase } from 'utils/fhirResourcesHelper'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { ValueSetSelectSingleForUnit } from 'views/components/common/singleSelectValueFromValueSetForUnit'
import { ValueSetSelectSingleForUnitRadio } from 'views/components/common/SingleSelectValueFromValueSetWithRadio'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '../../common/deleteConfirmationDialog'

interface Props {
  open: boolean
  onBackClick: () => void
  onContinueClick: () => void
}
export const AddUnitsHandler: React.FC<Props> = ({
  open,
  onBackClick: onClose,
  onContinueClick,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '60%',
    },
  }))

  const classes = useStyles()
  let organizationAddress: R4.IOrganization_Contact[] = []
  let organizationAddressTypes: R4.ICoding[] = []
  const mainOrganization: R4.IOrganization | undefined =
    getUserDetails()?.mainOrganization
  if (mainOrganization) {
    organizationAddress = mainOrganization.contact ?? []
    organizationAddressTypes = organizationAddress.map(
      (e) => e.purpose?.coding?.[0] ?? {}
    )
    console.log(organizationAddressTypes)
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<ConfirmationDialogProps>({
      open: false,
    })

  const [showError, setShowError] = useState<boolean>(false)
  const dispatch = useDispatch()

  const unitInvitationSetupSlice = useSelector(
    (state: RootState) => state.unitInvitationSetupSlice
  )

  useEffect(() => {
    if (unitInvitationSetupSlice.additionSuccessful) {
      onContinueClick()
      resetDetails()
    }
  }, [unitInvitationSetupSlice.additionSuccessful, onContinueClick])
  const { t } = useTranslation()
  const [displayWarning, setDisplayWarning] = useState<boolean>(true)
  const [organizations, setOrganizations] = useState<R4.IOrganization[]>([
    {
      resourceType: 'Organization',
      id: getUniqueTempId(),
    },
  ])

  const [unitDetails, setUnitDetails] = useState<UnitServices[]>([
    {
      serviceType: [],
      orgs: {
        resourceType: 'Organization',
        id: getUniqueTempId(),
      },

      systemOfMedicine: [],
      type: '',
      allopathyEssential: undefined,
      ayurvedaEssentials: undefined,
    },
  ])

  function handleChangeUnitTypeSelection(event: R4.ICoding, index: number) {
    // const values: R4.IOrganization[] = [...organizations]
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues[index].orgs.type = [
      {
        coding: [event],
      },
    ]

    unitValues[index].type = event.code ?? ''
    const code = {
      code: 'ADMIN',
      display: 'Administrative',
    }

    const selectedContact: R4.IOrganization_Contact | undefined =
      organizationAddress.find((e) => e.purpose?.coding?.includes(code))

    unitValues[index].orgs.contact = mainOrganization
      ? mainOrganization.contact ?? []
      : []

    if (event.code === 'lab' || event.code === 'collection_center') {
      unitValues[index].serviceType = []
      unitValues[index].systemOfMedicine = []
      unitValues[index].allopathyEssential = undefined
      unitValues[index].ayurvedaEssentials = undefined
    }
    setUnitDetails(unitValues)
  }

  function handleAllopathyLevel(event: R4.ICoding, index: number) {
    // const values: R4.IOrganization[] = [...organizations]
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues[index].allopathyEssential = event
    setUnitDetails(unitValues)
  }

  function handleAyurvedaLevel(event: R4.ICoding, index: number) {
    // const values: R4.IOrganization[] = [...organizations]
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues[index].ayurvedaEssentials = event
    setUnitDetails(unitValues)
  }

  function handleServiceType(event: R4.ICoding[], index: number) {
    // const values: R4.IOrganization[] = [...organizations]
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues[index].serviceType = event

    setUnitDetails(unitValues)
  }

  function handleMedicineType(event: R4.ICoding[], index: number) {
    // const values: R4.IOrganization[] = [...organizations]
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues[index].systemOfMedicine = event
    if (event.length === 0) {
      unitValues[index].allopathyEssential = undefined
      unitValues[index].ayurvedaEssentials = undefined
    }

    const allopathy = checkForAllopathy(unitValues[index])
    if (allopathy === true) {
      unitValues[index].allopathyEssential = undefined
    }
    const ayurVeda = checkForAyurveda(unitValues[index])
    if (ayurVeda === true) {
      unitValues[index].ayurvedaEssentials = undefined
    }

    setUnitDetails(unitValues)
  }

  function handleNameChange(event: any, index: number) {
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues[index].orgs.name = event.target.value
    setUnitDetails(unitValues)
  }

  const handleAddMore = () => {
    const unitValues: UnitServices[] = [...unitDetails]
    unitValues.push({
      serviceType: undefined,
      orgs: {
        resourceType: 'Organization',
        id: getUniqueTempId(),
      },

      systemOfMedicine: undefined,
      type: '',
      allopathyEssential: undefined,
      ayurvedaEssentials: undefined,
    })
    setUnitDetails(unitValues)
  }

  function deleteItem(i: number) {
    const values: UnitServices[] = [...unitDetails]
    values.splice(i, 1)
    setUnitDetails(values)
  }
  function handleContactDetailsChange(code: R4.ICoding, index: number) {
    const selectedContact: R4.IOrganization_Contact | undefined =
      organizationAddress.find((e) => e.purpose?.coding?.includes(code))
    if (selectedContact) {
      const values: UnitServices[] = [...unitDetails]
      values[index].orgs.contact = [selectedContact]
      setUnitDetails(values)
    }
  }
  function resetDetails() {
    const values: UnitServices[] = []
    values.push({
      serviceType: undefined,
      orgs: {
        resourceType: 'Organization',
        id: getUniqueTempId(),
      },

      systemOfMedicine: undefined,
      type: '',
    })
    setUnitDetails(values)
    setShowError(false)
  }

  function checkForAllopathy(unitService: UnitServices): boolean {
    const data: string[] = []

    if (unitService.systemOfMedicine) {
      for (let i = 0; i < unitService.systemOfMedicine.length; i++) {
        if (unitService.systemOfMedicine[i].code === 'C0242324') data.push('1')
      }
    }

    if (data.length > 0) return false
    return true
  }

  function checkForNaturopathy(unitService: UnitServices): boolean {
    const data: string[] = []

    if (unitService.systemOfMedicine) {
      if (unitService.systemOfMedicine.length === 1) {
        data.push('1')
      }
      for (let i = 0; i < unitService.systemOfMedicine.length; i++) {
        if (unitService.systemOfMedicine[i].code === '439809005') data.push('1')
      }
    }

    if (data.length > 1) return true
    return false
  }

  function checkForAyurveda(unitService: UnitServices): boolean {
    const data: string[] = []
    if (unitService.systemOfMedicine) {
      for (let i = 0; i < unitService.systemOfMedicine.length; i++) {
        if (unitService.systemOfMedicine[i].code === '1259939000')
          data.push('1')
      }
    }

    if (data.length > 0) return false
    return true
  }

  const handleSubmit = () => {
    let isAllValid: boolean = true
    unitDetails.forEach((e) => {
      if (!e.orgs.name || e.orgs.name?.length === 0) {
        if (e.orgs.name) {
          isAllValid = true
        } else {
          isAllValid = false
        }
      }

      //   if (e.orgs.contact === undefined || e.orgs.contact.length === 0) {
      //     alert('2455')
      //     isAllValid = false
      //   }

      if (e.orgs.type === undefined || e.orgs.type.length === 0) {
        isAllValid = false
      }
      if (e.type === 'clinic' || e.type === 'wellness_center') {
        if (
          e.systemOfMedicine === undefined ||
          (e.systemOfMedicine && e.systemOfMedicine.length === 0)
        ) {
          isAllValid = false
        }

        if (
          e.serviceType === undefined ||
          (e.serviceType && e.serviceType.length === 0)
        ) {
          isAllValid = false
        }
      }
    })
    if (isAllValid) {
      dispatch(sendUnitInvitations(unitDetails))
    }
    setShowError(!isAllValid)
  }

  function displayErrors() {
    let hasError: boolean = false
    for (let i = 0; i < unitDetails.length; i++) {
      if (unitDetails[i].orgs.name) {
        const name = unitDetails[i].orgs.name ?? ''
        if (name.length > 50) {
          hasError = true
        }
      }
    }
    return hasError
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetDetails()
        onClose()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title' disableTypography>
        {t('labelCommon:add_organization_unit_title')}
      </DialogTitle>
      <DialogContent dividers={true}>
        {unitInvitationSetupSlice.adding === true && (
          <Box display='flex' flexDirection='column'>
            <WelloLoadingIndicator message='Adding unit/units' />
          </Box>
        )}
        {unitInvitationSetupSlice.error === true && (
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='row'>
              <FontAwesomeIcon icon={faInfoCircle} color='red' />
            </Box>
            <Box display='flex' flexDirection='row'>
              <Typography>
                {unitInvitationSetupSlice.errorMessage ??
                  'Error while creating units .\n Please try later'}{' '}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row'>
              <Button variant='contained' onClick={() => {}}>
                {' '}
                <Typography>Retry</Typography>{' '}
              </Button>
            </Box>
          </Box>
        )}
        {unitInvitationSetupSlice.adding !== true &&
          unitInvitationSetupSlice.error !== true && (
            <Box display='flex' flexDirection='column' width='100%'>
              <Box display='flex' flexDirection='column' width='100%'>
                {unitDetails.map((org: UnitServices, index: number) => (
                  <Box
                    display='flex'
                    flexDirection='column'
                    id={`id_unit_details${org.orgs.id ?? index}`}
                    key={`unit_details${org.orgs.id ?? index}`}
                    width='100%'
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      id={`id_unit_details${org.orgs.id ?? index}`}
                      key={`unit_details${org.orgs.id ?? index}`}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='flex-start'
                        id={`id_unit_details${org.orgs.id ?? index}`}
                        key={`unit_details${org.orgs.id ?? index}`}
                        width='100%'
                      >
                        <Box
                          width='35%'
                          py={0.5}
                          px={1}
                          justifyContent='flex-start'
                        >
                          <WelloSelectFHIRCoding
                            title='Unit Type'
                            availableCodings={UnitTypes}
                            onChanged={(e) => {
                              if (e) {
                                handleChangeUnitTypeSelection(e, index)
                              }
                            }}
                            id={`id_unit_details${org.orgs.id ?? index}`}
                            textFieldProps={{
                              size: 'small',
                              error:
                                showError &&
                                (!unitDetails[index].orgs.type ||
                                  unitDetails[index].orgs.type?.length === 0),
                              helperText:
                                showError &&
                                (!unitDetails[index].orgs.type ||
                                  unitDetails[index].orgs.type?.length === 0)
                                  ? 'Unit Type is required'
                                  : '',
                            }}
                          />
                        </Box>
                        <Box
                          display='flex'
                          justifyContent='flex-start'
                          width='30%'
                          py={0.5}
                          px={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <WelloFormItemLabel title='Name' />
                            <TextField
                              variant='outlined'
                              size='small'
                              value={unitDetails[index].orgs.name}
                              fullWidth={true}
                              error={
                                showError ||
                                !unitDetails[index].orgs.name ||
                                unitDetails[index].orgs.name?.length === 0 ||
                                unitDetails[index].orgs.name!.length > 50
                              }
                              //   helperText={
                              //     showError ||
                              //     !unitDetails[index].orgs.name ||
                              //     unitDetails[index].orgs.name!.length > 50 ||
                              //     unitDetails[index].orgs.name!.length === 0
                              //       ? 'Name should be of 50 characters'
                              //       : ''
                              //   }
                              onChange={(changedValue) => {
                                handleNameChange(changedValue, index)
                              }}
                            />
                          </Box>
                        </Box>

                        {/* <Box
                          width='15%'
                          py={0.5}
                          px={1}
                          justifyContent='flex-start'
                        >
                          <WelloSelectFHIRCoding
                            title='Contact Information'
                            availableCodings={organizationAddressTypes}
                            onChanged={(e) => {
                              if (e) {
                                handleContactDetailsChange(e, index)
                              }
                            }}
                            id={`contct_type${org.orgs.id ?? index}`}
                            textFieldProps={{
                              size: 'small',
                              error:
                                showError &&
                                (unitDetails[index].orgs.contact ===
                                  undefined ||
                                  unitDetails[index].orgs.contact?.length ===
                                    0),
                              helperText:
                                showError &&
                                (unitDetails[index].orgs.contact ===
                                  undefined ||
                                  unitDetails[index].orgs.contact?.length === 0)
                                  ? 'Contact Information is required'
                                  : '',
                            }}
                          />
                        </Box> */}

                        <Box
                          width='35%'
                          py={0.5}
                          px={1}
                          justifyContent='flex-start'
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <ValueSetSelectMultiple
                              key='wellopathy-org-unit-service-type-vs-medicines'
                              title='Service Type'
                              url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-org-unit-service-type-vs'
                              id='serviceType'
                              fuzzySearch={true}
                              preSelectedOptions={org.serviceType ?? []}
                              disabled={
                                org.type === 'lab' ||
                                org.type === 'collection_center'
                              }
                              onOptionSelected={(selectedServiceType) => {
                                handleServiceType(selectedServiceType, index)
                              }}
                              helperText={undefined}
                              error={
                                org.serviceType === undefined ||
                                (org.serviceType &&
                                  org.serviceType.length === 0)
                              }
                            />
                          </Box>
                        </Box>
                        {unitDetails.length !== 1 && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='5%'
                            justifyItems='center'
                            paddingTop={6}
                          />
                        )}
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='flex-start'
                        id={`id_unit_details${org.orgs.id ?? index}`}
                        key={`unit_details${org.orgs.id ?? index}`}
                        width='100%'
                      >
                        <Box
                          width='35%'
                          py={0.5}
                          px={1}
                          justifyContent='flex-start'
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='100%'
                          >
                            <ValueSetSelectMultiple
                              key='wellopathy-system-of-medicines-vs-medicines'
                              title='System of Medicine'
                              url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-system-of-medicines-vs'
                              id='systemOfMedicine'
                              fuzzySearch={true}
                              preSelectedOptions={org.systemOfMedicine ?? []}
                              disabled={
                                org.type === 'lab' ||
                                org.type === 'collection_center'
                              }
                              onOptionSelected={(selectedServiceType) => {
                                handleMedicineType(selectedServiceType, index)
                              }}
                              helperText={undefined}
                              error={
                                org.systemOfMedicine === undefined ||
                                (org.systemOfMedicine &&
                                  org.systemOfMedicine.length === 0)
                              }
                            />
                          </Box>
                        </Box>
                        {org.systemOfMedicine &&
                          org.systemOfMedicine.length > 0 && (
                            <Box
                              display='flex'
                              justifyContent='flex-start'
                              width='65%'
                            >
                              <Box
                                display='flex'
                                justifyContent='flex-start'
                                width='46.5%'
                                py={0.5}
                                px={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {checkForAllopathy(org) === true &&
                                    checkForNaturopathy(org) === false && (
                                      <ValueSetSelectSingleForUnitRadio
                                        title='Capability Hierarchy for Ayurveda'
                                        url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-capability-hierarchy-vs'
                                        id='level2'
                                        fuzzySearch={true}
                                        preSelectedOptions={
                                          org.ayurvedaEssentials
                                        }
                                        //   disabled={
                                        //     (org.systemOfMedicine &&
                                        //       org.systemOfMedicine.length === 0) ||
                                        //     checkForAllopathy(org) === true
                                        //   }
                                        disabled={false}
                                        onOptionSelected={(selectedKriya) => {
                                          if (selectedKriya) {
                                            handleAyurvedaLevel(
                                              selectedKriya,
                                              index
                                            )
                                          }
                                        }}
                                        helperText={undefined}
                                        error={
                                          checkForAyurveda(org) === false &&
                                          org.ayurvedaEssentials === undefined
                                        }
                                      />
                                    )}
                                  {checkForAllopathy(org) === false && (
                                    <ValueSetSelectSingleForUnitRadio
                                      title='Capability Hierarchy for Allopathy'
                                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-allopathy-capability-hierarchy-vs'
                                      id='level1'
                                      fuzzySearch={true}
                                      preSelectedOptions={
                                        org.allopathyEssential
                                      }
                                      //   disabled={
                                      //     (org.systemOfMedicine &&
                                      //       org.systemOfMedicine.length === 0) ||
                                      //     checkForAllopathy(org) === true
                                      //   }
                                      disabled={false}
                                      onOptionSelected={(selectedKriya) => {
                                        if (selectedKriya) {
                                          handleAllopathyLevel(
                                            selectedKriya,
                                            index
                                          )
                                        }
                                      }}
                                      helperText={undefined}
                                      error={
                                        checkForAllopathy(org) === false &&
                                        org.allopathyEssential === undefined
                                      }
                                    />
                                  )}
                                </Box>
                              </Box>

                              <Box
                                display='flex'
                                justifyContent='flex-start'
                                width='55%'
                                py={0.5}
                                px={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  {/* {checkForAyurveda(org) === true && (
                                    <ValueSetSelectSingleForUnit
                                      title='Capability Hierarchy'
                                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-allopathy-capability-hierarchy-vs'
                                      id='level1'
                                      fuzzySearch={true}
                                      preSelectedOptions={
                                        org.allopathyEssential
                                      }
                                      //   disabled={
                                      //     (org.systemOfMedicine &&
                                      //       org.systemOfMedicine.length === 0) ||
                                      //     checkForAllopathy(org) === true
                                      //   }
                                      disabled={false}
                                      onOptionSelected={(selectedKriya) => {
                                        if (selectedKriya) {
                                          handleAllopathyLevel(
                                            selectedKriya,
                                            index
                                          )
                                        }
                                      }}
                                      helperText={undefined}
                                      error={
                                        checkForAllopathy(org) === false &&
                                        org.allopathyEssential === undefined
                                      }
                                    />
                                  )} */}

                                  {checkForAyurveda(org) === false &&
                                    checkForAllopathy(org) === false &&
                                    checkForNaturopathy(org) === false && (
                                      <ValueSetSelectSingleForUnitRadio
                                        title='Capability Hierarchy for Ayurveda'
                                        url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-ayurveda-capability-hierarchy-vs'
                                        id='level2'
                                        fuzzySearch={true}
                                        preSelectedOptions={
                                          org.ayurvedaEssentials
                                        }
                                        //   disabled={
                                        //     (org.systemOfMedicine &&
                                        //       org.systemOfMedicine.length === 0) ||
                                        //     checkForAllopathy(org) === true
                                        //   }
                                        disabled={false}
                                        onOptionSelected={(selectedKriya) => {
                                          if (selectedKriya) {
                                            handleAyurvedaLevel(
                                              selectedKriya,
                                              index
                                            )
                                          }
                                        }}
                                        helperText={undefined}
                                        error={
                                          checkForAyurveda(org) === false &&
                                          org.ayurvedaEssentials === undefined
                                        }
                                      />
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          )}

                        {unitDetails.length !== 1 && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            width='5%'
                            justifyItems='center'
                            paddingTop={6}
                          >
                            <DeleteOutlineOutlined
                              fontSize='small'
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setShowDeleteConfirmation({
                                  open: true,
                                  onContinueActionClicked: () => {
                                    setShowDeleteConfirmation({ open: false })
                                    deleteItem(index)
                                  },
                                  onCancelActionClicked: () => {
                                    setShowDeleteConfirmation({ open: false })
                                  },
                                  displayText:
                                    'Are you sure you want to delete this record?',
                                  continueActionButtonText: 'Delete',
                                  cancelActionButtonText: 'Cancel',
                                })
                              }}
                            />
                          </Box>
                        )}
                      </Box>

                      <Box>
                        {unitDetails.length - 1 === index &&
                          unitDetails.length < 4 && (
                            <Box
                              display='flex'
                              alignItems='center'
                              py={0.5}
                              flexDirection='row'
                            >
                              <Box>
                                <Button variant='text' onClick={handleAddMore}>
                                  <Typography
                                    variant='subtitle2'
                                    color='primary'
                                  >
                                    {t('labelCommon:unit_add_more_unit')}
                                  </Typography>
                                </Button>
                              </Box>
                              <Box
                                flexGrow={1}
                                paddingLeft={2}
                                paddingRight={1}
                              >
                                {' '}
                                <Divider />{' '}
                              </Box>
                            </Box>
                          )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box px={1}>
                <Collapse in={displayWarning}>
                  <Alert
                    variant='outlined'
                    severity='info'
                    icon={<InfoIcon />}
                    onClose={() => {
                      setDisplayWarning(false)
                    }}
                    hidden={displayWarning}
                  >
                    Please add all the departments or units of your organization
                    here. The unit types included in your package are available
                    to you. You can add more units later as well.
                  </Alert>
                </Collapse>
              </Box>
              {showDeleteConfirmation.open && (
                <ConfirmationDialog {...showDeleteConfirmation} />
              )}
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        <Box
          paddingRight={2.8}
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
        >
          <Button
            //   color='primary'
            onClick={() => {
              setShowError(false)
              resetDetails()
              onClose()
            }}
            variant='outlined'
            disableElevation
            size='small'
          >
            Cancel
          </Button>
          <Box paddingLeft={0.5}>
            <Button
              variant='contained'
              size='small'
              color='primary'
              onClick={handleSubmit}
              disabled={displayErrors()}
            >
              {t('labelCommon:continue')}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
